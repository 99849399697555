// Libraries
import React, {useEffect, useState, useContext} from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import InputFactory from '../../components/inputFactory'
import Button from '../../components/button'
import ProfilePageConfirmDelete from '../../components/admin/users/profilePageConfirmDelete'
import PrivateRoute from '../../components/privateRoute'
import profilePagePlaceholder from '../../img/background/profile-page-placeholder.png'
import DonateCta from '../../components/donateCta'

// Services
import UserService from '../../services/user'
import TrustService from '../../services/trust'
import EmploymentStatusService from '../../services/employmentStatus'
import ApiService from '../../services/api'
import AuthService from '../../services/auth'

// Context
import NotificationContext from '../../contexts/notification'

export default function ProfilePage() {
	const
		[formData, setFormData] = useState({}),
		[initialFormData, setInitialFormData] = useState({}),
		[activeFormData, setActiveFormData] = useState({}),
		[trusts, setTrusts] = useState({}),
		[employerName, setEmployerName] = useState(''),
		[employmentStatuses, setEmploymentStatuses] = useState({}),
		[modal, setModal] = useState(false),
		[user, setUser] = useState(false)

	const {addNotification} = useContext(NotificationContext)

	useEffect(() => {
		const userService = new UserService(),
			trustService = new TrustService(),
			employmentStatusService = new EmploymentStatusService()

		userService.getUser().then(async (response) => {
			let user = response?.data,
				addresses = response?.data?.addresses

			const formData = user

			if(addresses.length) {
				formData.address = addresses[0]
			}

			setUser({
				...user,
			})

			setEmployerName(user.organisation?.name || '')

			const employmentStatusResponse = await employmentStatusService.get();
			let employmentStatuses = employmentStatusResponse.data.map((item) => {
				return {
					label: item.name,
					value: item.id,
				}
			})
			setEmploymentStatuses(employmentStatuses)
			formData['employment_status_id'] = employmentStatuses.find(employmentStatus => {
				return employmentStatus.value === user?.employment_status_id
			})

			const trustsResponse = await trustService.get();
			let trusts = trustsResponse.data.map((item) => {
				return {
					label: item.name,
					value: item.id,
				}
			})
			setTrusts(trusts)
			formData['trust_id'] = trusts.find(trust => {
				return trust.value === user?.trust_id
			})

			setFormData(formData)

			const initialFormData = {...formData}
			setInitialFormData(initialFormData)
		})
	}, [])

	const handleInputChange = (value, name, scope) => {
		if(value) {
			// Update field value
			if (scope) {
				formData[scope][name] = value
			}
			else {
				formData[name] = value
			}

			setActiveFormData({
				[name]: value
			})
		}
		else {
			// Remove field value
			if (scope) {
				delete formData[scope][name]
			}
			else {
				delete formData[name]
			}
		}

		// Update state of file input values
		setFormData(formData)
	}

	const handleSave = async (name) => {
		const userService = new UserService(),
			apiService = new ApiService()

		let response

		if(name === 'address') {
			activeFormData.address.given_name = user.first_name
			activeFormData.address.family_name = user.last_name
			activeFormData.address.country_code = 'GB'
			activeFormData.address.is_primary = 1
			activeFormData.address.is_billing = 1
			activeFormData.address.is_shipping = 1

			const addressData = JSON.stringify(activeFormData.address)
			await apiService.put(`users/${user.id}/address/primary`, {body: addressData})
				.then((response) => {
					// did postal code change?
					if (user.addresses[0]?.postal_code !== activeFormData.address.postal_code) {
						user.addresses[0].postal_code = activeFormData.address.postal_code
						response.data.postal_code_change_count = parseInt(response.data.postal_code_change_count) + 1
					}

					setUser({
						...response.data,
					})
				})
		}
		else if(name === 'employment_status_id' || name === 'trust_id') {
			const selectData = {}

			selectData[name] = activeFormData[name].value
			response = await userService.updateUser(selectData)
		}
		else {
			response = await userService.updateUser(activeFormData)
		}

		if(response?.errors) {
			// Get values from errors object
			const errors = Object.values(response?.errors)

			// Loop errors and display as alerts
			errors.forEach((value) => {
				addNotification(value, 'error')
			})
		}

		setActiveFormData({})

		const initialFormData = {...formData}
		setInitialFormData(initialFormData)
	}

	const deleteUser = async () => {
		const userService = new UserService()
		const authService = new AuthService()

		const response = await userService.deleteUser()

		if(response?.success) {
			addNotification('Profile deleted successfully', 'success')
			await authService.logOut()
		}
		else {
			addNotification('Failed to delete profile', 'error')
		}
	}

	return (
		<PrivateRoute>
			<Layout>
				<Seo title="Profile" />
				<section className="profile">
					<div className="title-container">
						<h1 className="generic-template__title outline-title">My Profile</h1>
						<Button to="/profile/change-password" xsBlock>Change Password</Button>
						<Button hollow xsBlock onClick={() => setModal(true)}>Delete profile</Button>
					</div>

					{modal &&
						<ProfilePageConfirmDelete
							handleClose={() => setModal(false)}
							handleConfirm={deleteUser} >
							<Button onClick={deleteUser}>Actually Delete profile</Button>
							<Button onClick={() => setModal(false)}>Close Modal</Button>
						</ProfilePageConfirmDelete>
					}

					<form className="panel">
						<div className="inner">
							<div className='group1'>
								<InputFactory
									type="text"
									name="first_name"
									label="First Name"
									value={formData?.first_name}
									readOnly
									labelEndeavour
								/>

								<InputFactory
									type="text"
									name="last_name"
									label="Surname"
									value={formData?.last_name}
									readOnly
									labelEndeavour
								/>

								<InputFactory
									type="text"
									name="job_title"
									label="Job Title"
									initialValue={initialFormData?.job_title}
									value={formData?.job_title}
									editToggle
									onChange={handleInputChange}
									onSave={handleSave}
									labelEndeavour
								/>

								<InputFactory
									type="text"
									name="employer"
									label="Employer"
									value={employerName}
									readOnly
									labelEndeavour
								/>

								<InputFactory
									type="select"
									name="employment_status_id"
									label="Employment Status"
									placeholder=""
									options={employmentStatuses}
									initialValue={initialFormData?.employment_status_id}
									value={formData?.employment_status_id}
									editToggle
									className="select-field"
									onChange={handleInputChange}
									onSave={handleSave}
									labelEndeavour
								/>

								<InputFactory
									type="address"
									name="address"
									label="Address*"
									initialValue={initialFormData?.address}
									value={formData?.address}
									onChange={handleInputChange}
									onSave={handleSave}
									editToggle
									editBlock={user.postal_code_change_count >= 2}
									labelEndeavour
								/>

								<InputFactory
									type="text"
									name="email"
									label="Email Address"
									value={formData?.email}
									readOnly
									labelEndeavour
								/>

								<InputFactory
									type="text"
									name="phone"
									label="Mobile Number"
									initialValue={initialFormData?.phone}
									value={formData?.phone}
									editToggle
									onChange={handleInputChange}
									onSave={handleSave}
									labelEndeavour
								/>

								{user?.organisation?.name === 'NHS' && <InputFactory
									type="select"
									name="trust_id"
									label="Trust"
									options={trusts}
									initialValue={initialFormData?.trust_id}
									value={formData?.trust_id}
									editToggle
									className="select-field"
									onChange={handleInputChange}
									onSave={handleSave}
									labelEndeavour
									requireSearch={true}
								/>}
							</div>

							<div className="profile-image-upload">
								<InputFactory
									type="file"
									name="identification"
									label='fileID'
									value={formData?.identification}
									editToggle
									initialFilePreview={formData?.identification || profilePagePlaceholder}
									profile={true}
									onChange={handleInputChange}
									onSave={handleSave}
								/>
							</div>
						</div>
					</form>
				</section>
				<DonateCta />
			</Layout>
		</PrivateRoute>
	)
}