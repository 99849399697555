// Libraries
import * as React from 'react'

// Components
import Modal from '../../modal'
import Button from '../../button'

class ProfilePageConfirmDelete extends React.Component {
	render() {
		const {handleClose, handleConfirm} = this.props

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-delete">
				<h2 className="modal-title">Are you sure you want to delete your profile?</h2>
				<p>All of your entries will be withdrawn and your data will be deleted from our system</p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour backButton>
						Back
					</Button></li>
					<li><Button onClick={handleConfirm} colorPomegranate>
						Delete User
					</Button></li>
				</ul>
			</div>
		</Modal>
	}
}

export default ProfilePageConfirmDelete